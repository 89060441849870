<template>
	<PageLayout :title="$t('deposit.title')">
		<form id="perfectmoneyRedir" action="https://perfectmoney.is/api/step1.asp" method="post">
			<input id="formMerchantId" type="hidden" name="PAYEE_ACCOUNT" />
			<input id="formPayeeName" type="hidden" name="PAYEE_NAME" />
			<input id="formAmount" type="hidden" name="PAYMENT_AMOUNT" />
			<input type="hidden" name="PAYMENT_UNITS" value="USD" />
			<input id="formTransactionId" type="hidden" name="PAYMENT_ID" />
			<input id="formStatusUrl" type="hidden" name="STATUS_URL" />
			<input id="formPaymentUrl" type="hidden" name="PAYMENT_URL" />
			<input type="hidden" name="PAYMENT_URL_METHOD" value="GET" />
			<input id="formNoPaymentUrl" type="hidden" name="NOPAYMENT_URL" />
			<input type="hidden" name="NOPAYMENT_URL_METHOD" value="GET" />
			<input type="hidden" name="AVAILABLE_PAYMENT_METHODS" value="account, voucher, sms, wire" />
		</form>
		<div v-if="loading" class="loader-wrapper">
			<loader />
		</div>
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="'perfectmoney.png'" />
			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="'USD'"
				:form-errors="formErrors['amount']"
				:current-method="currentMethod"
				@set-button-value="setButtonValue"
				@input-amount-value-validation="setFormValidation"
			/>
			<span class="amount-error">{{ formErrors.amount }}</span>
			<div class="small-width">
				<promocode
					:form-promo-value="form.promocode"
					:form-error="formErrors['promocode']"
					@set-promo-value="setPromoValue"
				/>
			</div>

			<button type="button" class="button primary-button deposit center" :disabled="!form.validated" @click="pay()">
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositPerfectMoney',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		promocode,
		paymentWallet,
	},
	data() {
		return {
			cashierUrl: null,
			cashierKey: null,
			cashierToken: null,
			form: {
				amount: null,
				promocode: null,
				validated: true,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['isEmbedded']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('perfectmoney');
		},
	},
	async mounted() {
		this.loading = false;
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		setFormValidation(event) {
			this.form.validated = event;
		},
		async createTransaction(amount, currency, promocode) {
			const response = await apiClient.perfectMoneyCreateTransaction(this.walletId, amount, currency, promocode);

			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		async pay() {
			this.loading = true;

			try {
				const currency = 'USD';
				const result = await this.createTransaction(this.form.amount, currency, this.form.promocode);

				Analytics.trackEvent({
					event: 'Deposit',
					value: this.form.amount,
					label: 'PerfectMoney',
				});

				const form = document.getElementById('perfectmoneyRedir');
				document.getElementById('formAmount').value = this.form.amount;
				document.getElementById('formPayeeName').value = this.brandName;
				document.getElementById('formTransactionId').value = result.transactionId;
				document.getElementById('formMerchantId').value = result.merchantId;
				document.getElementById(
					'formPaymentUrl'
				).value = `${window.location.origin}/payments/deposit/confirmation/perfectmoney_success`;
				document.getElementById(
					'formNoPaymentUrl'
				).value = `${window.location.origin}/payments/deposit/confirmation/perfectmoney_failed`;
				document.getElementById('formStatusUrl').value = `${this.backendUrl}/payment/perfectmoney/webhook`;

				if (this.isEmbedded) {
					form.setAttribute('target', '_blank');
					this.$router.push({
						path: '/payments/deposit/confirmation',
						query: { status: 'external' },
					});
				}

				form.submit();
			} catch (e) {
				console.error(e);
				if (e.response?.status === 422) {
					const { errors } = e.response.data;
					errors.forEach(({ param, msg }) => {
						this.formErrors[param] = msg;
					});
					return;
				}

				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
				Sentry.captureException(e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped>
#perfectmoneyRedir {
	visibility: hidden;
}
</style>
